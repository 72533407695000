import { Link } from "react-router-dom";
import "./HellNewsHome.css";
import SportsWidget from "./SportsWidget";
import Stocks from "./Stocks";
import NewsPreview from "./NewsPreview";
import { articles } from "./ArticleData";
import SatanSpeaks from "../SatanSpeaks/SatanSpeaks";

function HellNewsHome({ handleHamburgerClick }) {
    return (
        <div className="app-wrap">
            <div className="big-container">
                <div className="buffer-top"></div>
                <div className="heading-content-container">
                    <img className="daily-demon" src="./images/daily-demon-logo.png" alt="daily-demon" />
                </div>
                <div className="sports-and-stocks-container">
                    <img className="swipe" src="./images/swipe.gif" alt="swipe" />
                    <div className="sports-container">
                        <SportsWidget />
                    </div>
                    <div className="stocks-container">
                        <Stocks />
                    </div>
                </div>
                <div className="articles-container">
                    {articles.map((article, index) => (
                        <Link 
                        to={`/news-page/${index}`}  
                        key={index} 
                        className={`individual-art-container individual-art-container-${index}`}                     >
                        <NewsPreview articleIndex={index} />
                    </Link>
                    
                    ))}
                </div>
            </div>
            <footer className='footer-container'>
                <div className='hamburger-icon' onClick={handleHamburgerClick}>
                    <img className='hamburger-icon-image' src="/images/hamburger-sign.png" alt="hamburger-icon-footer-nav" />
                </div>
                <SatanSpeaks />
            </footer>
        </div>
    );
}

export default HellNewsHome;
