import React from 'react';
import { useParams } from 'react-router-dom';
import { articles } from './ArticleData';
import SatanSpeaks from "../SatanSpeaks/SatanSpeaks";
import "./NewsPage.css";

function NewsPage({ handleHamburgerClick }) {
    const { articleId } = useParams();
    const article = articles[articleId];

    if (!article) {
        return <div>No article selected. Please return to the homepage and select an article.</div>;
    }

    return (
        <div className="app-wrap">
            <div className="darkening-container">
                <div className="main-container">
                    <div className="buffer-top"></div>
                    <div className="article-wrap">
                        <div className="headline-2">{article.headline}</div>
                        <div className="subheadlines-2">
                            {article.subHeadlines.split("\n").map((subHeadline, index) => (
                                <div key={index} className="subheadline-item">
                                    • {subHeadline}
                                </div>
                            ))}
                        </div>
                        <div className="image-container-2">
                            <img className="article-image-2" src={article.image} alt={article.imageCaption} />
                        </div>
                        <div className="image-caption-2">{article.imageCaption}</div>
                        <div className="byline-2">{article.byline}</div>
                        <div className="article-copy-2">
                            {article.articleCopy.split("\n\n").map((paragraph, index) => (
                                <p key={index} className="indented-paragraph">{paragraph}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <footer className='footer-container'>
                <div className='hamburger-icon' onClick={handleHamburgerClick}>
                    <img className='hamburger-icon-image' src="/images/hamburger-sign.png" alt="hamburger-icon-footer-nav" />
                </div>
                <SatanSpeaks />
            </footer>
        </div>
    );
}

export default NewsPage;
