import React from "react";
import { articles } from "./ArticleData"; 
import "./NewsPreview.css";


function NewsPreview({ articleIndex }) {
    const article = articles[articleIndex];

    if (!article) return null;

    return (
        <div className="big-wrap">
            <div className="headline">
                {article.headline}
            </div>
            <div className="article-content-container">
                <div className="left-side-article-content">
                    <img className="image-container article-image"
                        src={article.image}
                        alt={article.imageCaption}
                    />
                    <div className="image-caption">
                        {article.imageCaption}
                    </div>
                </div>
                <div className="right-side-article-content">
                    <div className="subheadlines">
                        {article.subHeadlines.split("\n").map((subHeadline, index) => (
                            <div key={index} className="subheadline-item">
                                • {subHeadline}
                            </div>
                        ))}
                    </div>
                    <div className="byline">
                        {article.byline}
                    </div>
                    <div className="article-copy preview-text">
                        {article.articleCopy}
                        <img className="read-more" src="/images/read-more.png" alt="read-more"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsPreview;
