// This is the database of articles and their respective bits.
// We store them in an array called "articles"
// This page interacts mainly with App.js/HellNewsHome.js/NewsPreview/NewsPage (Big to small components built on top of one another)


export const articles = [
    {
      headline: "Flesh Monger Union Plan Rejected by Gov't",
      image: "/images/Man-in-the-court-room.jpg",
      imageCaption: "Union President Oog'Roo'Dlook and attorney Fezel-Ditz react as the carnal limitations statute is read at last hellmester's hearing. Both of them will consume limited flesh on the union's behalf says Daily Demon staff.",
      subHeadlines: "Insufficient Rotting Flesh is Cited\nGov't Claims Adequate Stinkiness",
      byline: "By Blazy McLazy",
      articleCopy: `CANNIBALAPOLIS –  The 9th circuit of the Satanic Supreme Court ruled against the Flesh Eaters Union, stating “…the time will come when eternal damnation clauses are amended with respect to available public [meat]. But today is not that day. You clearly have enough meat.”
  
  Union President Oog’Roo’Dlook shrieked out in exalted pain at the jury, the velocity of his breath melting all but one juror. \n\nThis selfless act was met with mutual adulation from both Judge Belphegor and the punishment committee.
  
  Belphegor melted the last juror as a token of appreciation; he scheduled their hellacious revival in a lower pit, and extended the warrant barring them from purgatory applications indefinitely—just because he personally finds Oog’Roo’Dlook to be a dishonest and violent demon, worthy of hell’s highest praise.
  
  \n\nDlook’s attorney Fezel-Ditz has claimed that they will appeal the decision so that they can eat more flesh, “in an effort to satisfy the gluttony clause of the 3rd Inferno Charter of the Daemon era, we must adhere to previous jurisprudence and continue to promote ravenous consumption. It is the only way.”
  
  \n\nWhen asked about the often-cited rotten-flesh amendment, Fezel-Ditz remarked “There needs to be a paradigm shift on how rotten-flesh law is currently processed under our current government.
  
  What good is it murdering an entire jury if our current overlords don’t want to unequally distribute their flesh among the union members. I mean, they pay their dues, right? What more do they want?" \n\nJudge Belphegor could not be reached for comment. `
    },
    {
        headline: "Brutus and Judas Release Avant-garde Album",
        image: "/images/judas-brutus.jpg",
        imageCaption: "The layering of the gut string guitars through the blood amps by Brutus creates a sonic blast, a literal sonic blast that has killed people every time it has been played.",
        subHeadlines: "Brutus Injected Blood in Amps\nJudas Leaves Ego at the Door",
        byline: "By Infernus J. Sanguin-Sparks",
        articleCopy: `MAYHEMVILLE - Moves are clearly being made in The Lower Ninth’s trip-hop scene, with the iconic duo returning for their first release in over a millennium. From the first crisp beats of the opener “Mezohemoth (tight beast)” to the last moaning aches of the uncredited Whores of Babylon in the finale track “Stridor”, both Bru and The Jew are relentless with gnashing rhymes and oozing synths.\n\n

The layering of the gut string guitars through the blood amps by Brutus creates a sonic blast, a literal sonic blast that has killed people every time it has been played. Judas whispers and howls violently beautiful poetry, including several passages where he drops the entire inner monologue of Timothy McVeigh during his execution.\n\n 

The lead single “Grignr” pulsates with throbbing bloodnoise, with guest ad libs from both Rommel and Cromwell. Judas squelches “Ya na kadishtu nilgh'ri stell'bsna Nyogtha/K'yarnak phlegethor l'ebumna syha'h n'ghft” in a powerful testament against his inner struggles, as Brutus slams Eric Clapton’s baby through a large harp for harmonious effects. They sampled over 1,000 different species of bats fornicating to create the rhythms for 7 different tracks, and not a single bat fuck is repeated.\n\n 

Under the stylistic guidance of their newest producers J Edgar Hoover and Circe (Swine Records), the masterpiece of Hell’s most eclectic duo could not come at a more perfect time. Although not together for several hundred years after the drama involving Brittany Murphy and Derek Jeter’s white mom, they have been continuously called out in scene by Robin Williams, Stevie Ray Vaughan, and JonBenet Ramsey, the queen of the underworld herself. They fire back in nearly every track in this record, notably calling Woodrow Wilson a “fucking doucheknuckle” and insinuating that Queen Victoria is still sleeping with Drake.\n\n 

Several of our premiere radio hosts here in The Downstairs have been giving the 3 singles extensive playtime, including Caylee Anthony on Suck FM 84.3 and Cary Grant on the Spawn Network.\n\n 

We’re gonna have to be honest here, this one really blew our dicks off. Hell News is prepared to give Brutus/Judas (2024) a perfectly luciferian 0.0/10, the worst possible score a musical piece has achieved in our 4 eons of reviews! We wish the pair the worst of luck as they continue to mutilate your ears for eternity.\n\n

Like what you’ve read here? Have feedback? Go fuck yourself.\n\n 

`
    },
    {
        headline: "Beelzebub Faces Public Backlash from Affair",
        image: "/images/beezelbub.jpg",
        imageCaption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        subHeadlines: "Beelzebub has over 8,000 STDs\nHints at early retirement",
        byline: "Byline 3",
        articleCopy: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
    }
];
